import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const loader = () => {
  return (
    <div className="text-center" style={{ marginTop: "calc(30vh)" }}>
      <Spinner animation="border" />
    </div>
  );
};

const Layout = React.lazy(() => import("../pages/layout"));
const AppLayout = React.lazy(() => import("../pages/app/layout"));

export default () => (
  <BrowserRouter>
    <Suspense fallback={loader()}>
      <Routes>
        <Route path="/app/*" element={<AppLayout />} />
        <Route path="/*" element={<Layout />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);
