import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import PsContext from "./index";
import { PS_WEB, getLs, setLs, upperCase } from "../utils";
import { listSettingsFields } from "../models/fieldSettings";
import { Spinner } from "react-bootstrap";

import jwt from "jwt-simple";

const PsContextProvider = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  const token = PS_WEB;

  const isMobileView = () => (windowWidth < breakpoint ? true : false);

  const checkUserLogged = () => {
    return getLs("tnportal_logged") || "no";
  };

  const checkUserMobilePassword = () => {
    return getLs("tnportal_mob_pass") || "";
  };

  const checkMobileUserId = () => {
    return getLs("tnportal_mob_uuid") || "";
  };

  const getAdminUser = () => {
    return getLs("tnportal_user_data") &&
      getLs("tnportal_user_data") !== "undefined"
      ? JSON.parse(getLs("tnportal_user_data"))
      : [];
  };

  const getLoggedCollege = () => {
    return getLs("tnportal_college_data") &&
      getLs("tnportal_college_data") !== "undefined"
      ? JSON.parse(getLs("tnportal_college_data"))
      : [];
  };

  const getAdminApi = () => {
    return getLs("tnportal_api") || "";
  };

  const getSettings = () => {
    /*return getLs("tnportalnt_settings") &&
      getLs("tnportalnt_settings") !== "undefined"
      ? JSON.parse(getLs("tnportalnt_settings"))
      : [];*/
    if (
      getLs("tnportalnt_settings") &&
      getLs("tnportalnt_settings") !== "undefined"
    ) {
      let set = getLs("tnportalnt_settings");
      if (!set.includes("{")) {
        let decoded = jwt.decode(set, token);
        setSettings(decoded);
        return decoded;
      }
      return [];
    } else {
      setSettings([]);
      return [];
    }
  };

  const getAcyear = () => {
    return getLs("tnportalnt_acyear_cur") &&
      getLs("tnportalnt_acyear_cur") !== "undefined"
      ? JSON.parse(getLs("tnportalnt_acyear_cur"))
      : [];
  };

  const getPermissions = () => {
    return getLs("tnportalnt_user_permissions") &&
      getLs("tnportalnt_user_permissions") !== "undefined"
      ? JSON.parse(getLs("tnportalnt_user_permissions"))
      : [];
  };

  const getCashbook = () => {
    return getLs("tnportalnt_user_cashbook") &&
      getLs("tnportalnt_user_cashbook") !== "undefined"
      ? JSON.parse(getLs("tnportalnt_user_cashbook"))
      : [];
  };

  const [state, setState] = useState([]);

  const [logged, setLogged] = useState(checkUserLogged());

  const [mobilePass, setMobilePass] = useState(checkUserMobilePassword());
  const [mobileUserId, setMobileUserId] = useState(checkMobileUserId());

  const [user, setUser] = useState(getAdminUser());
  const [api, setApi] = useState(getAdminApi());

  const [backgroundProcess, setBackgroundProcess] = useState(false);

  //const [settings, setSettings] = useState(getSettings());
  const [settingsUpdated, setSettingsUpdate] = useState(false);
  const [settings, setSettings] = useState([]);
  const [acyear, setSAcyear] = useState(getAcyear());

  const [accessPermissions, setAccessPermissions] = useState(getPermissions());
  const [cashbook, setCashbook] = useState(getCashbook());

  // for affiliated college
  const [loggedCollege, setLoggedCollege] = useState(getLoggedCollege());

  const [selectedAdmissionYear, setadmissionYear] = useState("2022");

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (settings) forceUpdate();
  }, [settings]);

  useEffect(() => {
    getSettings();
  }, [settingsUpdated]);

  const saveLogin = (
    user,
    api,
    permissions = [],
    cb = [],
    loggedClg = false
  ) => {
    /*var now = new Date();
		var minutes = 1;
		now.setTime(now.getTime() + (minutes * 60 * 1000));*/

    setLs("tnportal_user_data", JSON.stringify(user));
    setUser(user);

    setLs("tnportal_api", api);
    setApi(api);

    setLs("tnportalnt_user_permissions", JSON.stringify(permissions));
    setAccessPermissions(permissions);

    setLs("tnportalnt_user_cashbook", JSON.stringify(cb));
    setCashbook(cb);

    if (loggedClg) {
      setLs("tnportal_college_data", JSON.stringify(loggedClg));
      setLoggedCollege(loggedClg);
    }

    //setLs('mat_logged', 'yes');
    //setLogged('yes');
  };

  const logout = () => {
    setLs("tnportal_user_data", false);
    setUser([]);
    setSAcyear([]);
    setLs("tnportal_api", "");
    setLs("tnportalnt_acyear_cur", false);
    setApi(null);
    axios.defaults.headers.common["Api-Token"] = "";
    setLs("tnportalnt_user_permissions", "");
    setAccessPermissions([]);
    setCashbook([]);
    setLs("tnportal_logged", "no");
    setLs("tnportal_college_data", false);
    setLoggedCollege([]);
    setLogged("no");
  };

  const decode = (v) => {
    try {
      return jwt.decode(v, token);
    } catch (er) {
      return [];
    }
  };

  const getDecodeApi = () => {
    try {
      let decoded = jwt.decode(api, token);
      return decoded;
    } catch (er) {
      return [];
    }
  };

  const setEncodeApi = (decoded) => {
    try {
      let encoded = jwt.encode(decoded, token);
      axios.defaults.headers.common["Api-Token"] = encoded;
      setLs("tnportal_api", encoded);
      setApi(api);
    } catch (er) {}
  };

  const updateUser = (us) => {
    if (us.academic_department) {
      let decoded = getDecodeApi();
      decoded.academic_department = us.academic_department;
      setEncodeApi(decoded);
    }
    setLs("tnportal_user_data", JSON.stringify(us));
    setUser(us);
    forceUpdate();
  };

  const updateCashbook = (cb) => {
    let decoded = getDecodeApi();
    decoded.allowd_cash_books = cb.id;
    setEncodeApi(decoded);
    setLs("tnportalnt_user_cashbook", JSON.stringify(cb));
    setCashbook(cb);
    forceUpdate();
  };

  const updateLogged = () => {
    setLs("tnportal_logged", "yes");
    setLogged("yes");
  };

  const loadSettings = () => {
    //setLoader(true);
    try {
      listSettingsFields("1", "1").then((res) => {
        if (res && res.status == "1") {
          if (res.permissions) {
            setLs(
              "tnportalnt_user_permissions",
              JSON.stringify(res.permissions)
            );
            setAccessPermissions(res.permissions);
          }
          if (res.data) {
            setLs("tnportalnt_settings", res.data);
            setSettingsUpdate(!settingsUpdated);
          }
          //setLoader(false);
        }
      });
    } catch (er) {
      setLoader(false);
    }
  };

  const settingValue = (key) => {
    let m = settings && settings.find((item) => item.field_name == key);
    return m && m.field_name ? m.field_value : false;
  };

  const updateAcyear = (v) => {
    setSAcyear(v);
    setLs("tnportalnt_acyear_cur", JSON.stringify(v));
  };

  const updateMobileUserId = (v) => {
    setMobileUserId(v);
    setLs("tnportal_mob_uuid", v);
  };

  const updateMobilePassword = (v) => {
    setLs("tnportal_mob_pass", v);
    setMobilePass(v);
  };

  const setStorage = (storageName, storageValue) => {
    setLs(storageName, storageValue);
    forceUpdate();
  };

  const getStorage = (storageName) => {
    return getLs(storageName); /* && getLs(storageName) !== "undefined"
      ? getLs(storageName)
      : null;*/
  };

  const allowedAccess = (module, action) => {
    let permissions = accessPermissions;
    try {
      if (upperCase(user.user_type) == "DEV") return true;

      /* if (props.roleGroup && props.roleGroup.length > 0) {
        let allowedRoleGroups = findCommonElement(
          permissions.map((item) => item.role_group),
          props.roleGroup
        );
        return allowedRoleGroups;
      }*/

      if (!module || !action) return false;

      let moduleExist = permissions.filter(
        (item) => upperCase(item.module) == upperCase(module)
      );

      if (moduleExist.length < 1) return false;

      let allowed = moduleExist.find(
        (item) => upperCase(item.action_name) == upperCase(action)
      );

      return allowed ? true : false;
    } catch (er) {
      return false;
    }
  };

  const forceUpdate = useCallback(() => setState({}), []);

  return (
    <PsContext.Provider
      value={{
        state: state,
        logged: logged,
        user: user,
        acyear: acyear,
        api: api,
        saveLogin: saveLogin,
        updateUser: updateUser,
        updateLogged: updateLogged,
        setBackgroundProcess: setBackgroundProcess,
        backgroundProcess: backgroundProcess,
        logout: logout,
        loadSettings: loadSettings,
        settingValue: settingValue,
        selectedAdmissionYear: selectedAdmissionYear,
        updateAcyear: updateAcyear,
        accessPermissions: accessPermissions,
        allowedAccess: allowedAccess,
        cashbook: cashbook,
        updateCashbook: updateCashbook,
        isMobileView: isMobileView,
        mobilePass: mobilePass,
        mobileUserId: mobileUserId,
        updateMobileUserId: updateMobileUserId,
        updateMobilePassword: updateMobilePassword,
        setLoader: setLoader,
        setStorage: setStorage,
        getStorage: getStorage,
        decode: decode,
        getDecodeApi: getDecodeApi,
      }}
    >
      {loader && (
        <div className="ps__loader">
          <div className="ps__loader__body">
            <div className="ps__loader__content">
              <Spinner animation="grow" size="lg" />
              <br />
              Please Wait
            </div>
          </div>
        </div>
      )}

      {props.children}
    </PsContext.Provider>
  );
};

export default PsContextProvider;
